<template>
  <div class="container">
    <div class="collect_search">
      <collect-search></collect-search>
      <!-- 采集 -->
      <div v-if="q == 1">
        <my-waterfall :data="collectData" @loadmore="loadmore"></my-waterfall>
      </div>
      <!-- 画板 -->
      <div v-if="q == 2">
        <draw-board :data="boardData"></draw-board>
      </div>
      <!-- 用户 -->
      <div v-if="q == 3"></div>
      </div>
  </div>
</template>

<script>
import CollectSearch from "@/components/CollectSearch.vue";
import MyWaterfall from '@/components/MyWaterfall';
import DrawBoard from '@/components/DrawBoard';
export default {
  components: {
    CollectSearch,
    MyWaterfall,
    DrawBoard,
  },
  data() {
    return {
      select: '1',
      text: '',

      q: '',

      collectData: [],
      collectQuery: {cat_id: '', username: '', ord: 0, title: '', use: 0, page: 1},

      boardData: [],
      boardQuery: {title: '', use: 0, username: '', cat_id: ''},
    }
  },
  methods: {
    getCollectData() {  // 采集数据
      this.$service.DEFAULT({
        url: '/auth/ppt/fodder/list',
        method: 'GET',
        params: {
          cat_id: this.collectQuery.cat_id,
          username: this.collectQuery.username,
          ord: this.collectQuery.ord,
          title: this.collectQuery.title,
          use: this.collectQuery.use,
          page: this.collectQuery.page,
        },
      }).then(res => {
        if (res.code == 200) {
          this.collectData = this.collectData.concat(res.data.res ? res.data.res : [])
        }
      })
    },
    getBoardData() {  // 画板数据
      this.$service.DEFAULT({
        url: '/auth/ppt/board/list',
        method: 'GET',
        params: {
          title: this.boardQuery.title,
          use: this.boardQuery.use,
          username: this.boardQuery.username,
          cat_id: this.boardQuery.cat_id,
        },
      }).then(res => {
        if (res.code == 200) {
          this.boardData = this.boardData.concat(res.data.res ? res.data.res : [])
        }
      })
    },
    loadmore() {   // 加载更多
      this.collectQuery.page += 1;
      this.getCollectData();
    },
    search() {
      this.collectData = [];
      this.getCollectData();
    },
  },
  created() {
    this.text = this.$route.query.by;
    if (this.$route.query.q == 1) {
      this.q = 1;
      this.collectQuery.title = this.$route.query.by;
      this.getCollectData();
    }
    if (this.$route.query.q == 2) {
      this.q = 2;
      this.boardQuery.title = this.$route.query.by;
      this.getBoardData();
    }
  },
}
</script>

<style lang="scss" scoped>
.container {
  box-sizing: border-box;
  width: 100%;
  min-width: 1200px;
  min-height: calc(100vh - 70px);
  background: #f5f5f5;
  padding-top: 20px;
  .collect_search {
    width: 90%;
    margin: 0 auto;
    .search {
      width: 100%;
      ::v-deep .el-input-group--append {
        .el-input-group__prepend {
          min-width: 120px;
        }
      }
    }
  }
}
</style>